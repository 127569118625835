
import ImageActionsCell1 from './ImageActionsCell1';
import ImageActionsCell2 from './ImageActionsCell2';

export default [
    {
        title: 'Date',
        key: 'attendancedate',
        sortable: true,
        minWidth: 100,
    },
	{
        title: 'Shift',
        key: 'shift',
		sortable: true,
        minWidth: 100,
    },
    {
        title: 'Time(In)',
        sortable: true,
        minWidth: 100,
        render: (h, params) => {
            return h('div', [
                h(ImageActionsCell1, {
                    props: {
                        row: params.row
                    }
                }),
            ]);
        }
    },
    {
        title: 'Time(Out)',
        sortable: true,
        minWidth: 100,
        render: (h, params) => {
            return h('div', [
                h(ImageActionsCell2, {
                    props: {
                        row: params.row
                    }
                }),
            ]);
        }
    },
    {
        title: 'No .of Hours',
        key: 'hours',
        sortable: true,
        minWidth: 100,	
    },
    {
        title: 'Notes',
        key: 'notes',
        sortable: true,
        minWidth: 100,	
    },
]
