<template>
    <div :class="$style.actions">
        <div >
            <template v-if="row.ImagePath === '~/Images/cross.gif'">
                <div v-html="row.timein"></div>
                <img :src="require(`Assets/images/cross.png`)" :class="$style.redCrossImage"/>
            </template>
            <template v-else>
                <div v-html="row.timein"></div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ActionsCell",
        props: {
            row: {
                type: Object,
                required: true,
            }
        },
        computed:{
            userData() {
                return this.$store.state.user.user
            }
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style lang="scss" module>
    .actions {
        display: flex;
        div {
            margin: 0px 7px 0px 0px;
            color: #515a6e;
            display: inline-block;
        }
    }

    .redCrossImage{
        width: 15px;
    }
</style>

