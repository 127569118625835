<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="Attendance Report" slot="title" link="/helpContent/Attendance Report" />
        <ValidationObserver ref="validator">
            <FormRow>
                <div class="col-sm-3">
                    <FormSelect label="Select Staff" :items="staffList" item-name="Name" item-value="id" v-model="filtering.selectedStaff"/>
                </div>
                <div class="col-sm-2">
                    <Year label="Year" v-model="filtering.selectedYear" rules="required" />
                </div>
                <div class="col-sm-2">
                    <InputMonth label="Month" v-model="filtering.selectedMonth"/>
                </div>
              
                <div class="col-sm-2">
                    <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                        <Icon type="ios-search" />
                    </FormButton>
                </div>
                <div class="col-sm-1">
                    <inputText label="Total Monthly Hours" v-model="form.totalMonthlyHours" :readonly="true" />
                </div>
            </FormRow>
        </ValidationObserver>
        <DataTable :actions="true" 
            :data="attendanceReport"
            :columns="tableConfig"
            :is-loading="isLoading">
            <template slot="actionsLeft">
            </template>
        </DataTable>
    </FormWrapper>
</template>

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import DataTable from 'Components/DataTable'
    import Year from "../../components/Year";
    import loadingMixin from 'Mixins/loadingMixin'
    import {getStaffAttendance, getAllStaffByHRorManager} from '../api'
    import tableConfig from './table'
    import validateSelectedRows from "Mixins/validateSelectedRows";
    import {ValidationObserver} from 'vee-validate'
    export default {
        name: "List",
        mixins: [loadingMixin, validateSelectedRows],
        components: {
            FormWrapper,
            DataTable,
            PageTitle,
            Year,
            ValidationObserver
        },
        data() {
            return {
                filtering: {
                    selectedStaff: '',
                    selectedYear:new Date().getFullYear(),
                    selectedMonth:new Date().getMonth() + 1,
                },
                form: {
                    totalMonthlyHours: ''
                },
                staffList: [],
                years: [],
                months: [],
                sorting: {
                    key: 'attendancedate',
                    order: 'asc'
                },
                pagination: {
                    perPage: 10,
                    page: 1,
                    total: 0
                },
                tableConfig,
                attendanceReport: [],
                showMessage: {
                    isVisible: false,
                    message: ''
                }, 
            }
        },
        computed: {
            userData() {
                return this.$store.state.user.user
            }
        },
        watch: {},
        created() {
            this.getAllList()
        },
        methods: {
            async getAllList() {
                await getAllStaffByHRorManager().then((res) => {
                    this.staffList = res.data
                    this.filtering.selectedStaff = this.userData.user_id;
                })
                setTimeout(() => {
                    this.getDataByFilter();
                }, 50); //50 miliSecond
            },
            search() {
                this.pagination.page = 1;
                this.getDataByFilter()
            },
            selectChange(data) {
                this.selectedRows = data;
            },
            sortChange(data) {
                this.sorting.key = data.key;
                this.sorting.order = data.order;
                this.getDataByFilter()
            },
            getDataByFilter() {
                this.$refs.validator.validate().then((result) => {
                    if (result) {
                        setTimeout(() => {
                                this.$refs.validator.validate().then((result) => {
                                    if (result) {
                                        this.showLoader();
                                        const {
                                            key,
                                            order
                                        } = this.sorting;
                                        const {
                                            perPage,
                                            page
                                        } = this.pagination;
                                        const filters = {};
                                        if (this.filtering.selectedStaff) {
                                            filters.selectedStaff = this.filtering.selectedStaff;
                                        }
                                        if (this.filtering.selectedYear) {
                                            filters.yearSelected = this.filtering.selectedYear;
                                        }
                                        if (this.filtering.selectedMonth) {
                                            filters.monthSelected = this.filtering.selectedMonth;
                                        }
                                        const data = {
                                            filterjson: {
                                                filter: [filters],
                                                sort: [{
                                                    key,
                                                    order
                                                }],
                                                paging: [{
                                                    startIndex: page,
                                                    pageSize: perPage
                                                }]
                                            }
                                        };
                                        getStaffAttendance(data)
                                            .then(this.handleResponse)
                                            .catch(this.handleError)
                                    }
                                })
                            },
                            50
                        )
                    }
                });
            },
            pageChange(page) {
                this.pagination.page = page;
                this.getDataByFilter()
            },
            handleResponse(response) {
                this.hideLoader();
                this.attendanceReport = response.data;
                this.pagination.total = response.NumberOfRecords;
                this.form.totalMonthlyHours = response.TotalMonthlyHours;
            },
        }
    }
</script>

<style lang="scss">
.popupRow {
    padding: 5px 0 15px;
}

.pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
}

.paginationText {
    margin: 0 20px;
}

.paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color .2s ease-in-out, color .2s ease-in-out, box-shadow 200ms ease
}

.paginationButton:hover {
    background: #57a3f3;
    color: #fff;

    &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    }
}

.filter {
    margin: -10px 0 10px;
}

.filterItem {
    display: inline-block;
    margin: 0 15px 15px 0;
}

.filterItemCheckbox {
    flex-basis: auto;
    align-self: flex-start;
    margin-top: 13px;
}
</style>
